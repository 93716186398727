
import { Options, Vue } from 'vue-class-component'
import dayjs from 'dayjs'
import { Practice } from '../models/practice'
import { Prop } from 'vue-property-decorator'

@Options({
  components: {}
})
export default class PracticeCard extends Vue {
  @Prop() isShowAction = false
  @Prop() name = ''
  @Prop() cover = ''
  @Prop() info = ''
  @Prop() isDailyPractice = false
  @Prop() practice!: Practice

  getDate (date: any) {
    return dayjs(date).format('M月D日 HH:mm')
  }
}
