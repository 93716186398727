
import { Options, Vue } from 'vue-class-component'
import PlanDetail from '@/components/PlanDetail.vue'
import service from '@/utils/request'
import dayjs from 'dayjs'
import { Plan } from '@/models/plan'
import NaviBar from '@/components/NaviBar.vue'
import { Practice } from '@/models/practice'
import PracticeCard from '@/components/PracticeCard.vue'
import { Toast } from 'vant'

@Options({
  components: {
    PracticeCard,
    NaviBar
  }
})
export default class PlanPracticeList extends Vue {
  planPractices: Practice[] = []
  planId = ''

  mounted () {
    this.planId = this.$route.params.planId as string

    this.loadPlanPractices(this.planId)
  }

  loadPlanPractices (planId: string) {
    service.get('/mindup/mini/plans/' + planId + '/practices', {}).then(res => {
      console.log(res.data)
      this.planPractices = res.data
    })
  }

  tapPractice (index: number) {
    service.post('/mindup/mini/practice/' + this.planPractices[index].id + '/daily-practice/add-or-cancel', {}).then(res => {
      if (res.data.isDailyPractice) {
        Toast.success('添加成功')
      } else {
        Toast.success('取消成功')
      }
      this.planPractices[index].isDailyPractice = res.data.isDailyPractice
    })
  }
}
